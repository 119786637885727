window.addEventListener("load", function (event) {
  var popups = document.querySelectorAll(".popup");

  popups.forEach(function (popup) {
    const storage = popup.classList.contains("each_session")
      ? window.sessionStorage
      : window.localStorage;

    if (!storage.getItem(popup.id)) {
      popup.classList.add("open");

      storage.setItem(popup.id, 1);
    }

    var exits = popup.querySelectorAll(".popup-close");
    exits.forEach(function (exit) {
      exit.addEventListener("click", function (event) {
        event.preventDefault();
        popup.classList.remove("open");
      });
    });
  });
});
