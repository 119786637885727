function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function handleElementInViewPort(element, inViewPort) {
  if (inViewPort) {
    element.classList.add("in-viewport");
  } else {
    element.classList.remove("in-viewport");
  }
}

$(function () {
  if (
    !("IntersectionObserver" in window) ||
    !("IntersectionObserverEntry" in window) ||
    !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
  ) {
    return;
  }

  let entries = document.querySelectorAll(".viewport-animate");

  const io = new IntersectionObserver(function (entries) {
    for (
      let element_index = 0;
      element_index < entries.length;
      element_index++
    ) {
      handleElementInViewPort(
        entries[element_index].target,
        entries[element_index].isIntersecting
      );
    }
  });

  for (let element_index = 0; element_index < entries.length; element_index++) {
    io.observe(entries[element_index]);
    handleElementInViewPort(
      entries[element_index],
      isElementInViewport(entries[element_index])
    );
  }
});
