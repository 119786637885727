class DropDownItem {
  constructor(menu, linkElement) {
    this.menu = menu;
    this.linkElement = linkElement;
    this.targetElement = document.querySelector(
      linkElement.attributes.href.nodeValue
    );

    this.bindObservers();
  }

  get id() {
    return this.linkElement.dataset.menuId;
  }

  get parent() {
    if (this.linkElement.dataset.parentMenuId) {
      let parentMenuId = this.linkElement.dataset.parentMenuId;

      return this.menu.dropdownItems.find((dropdownItem) => {
        return dropdownItem.id == parentMenuId;
      });
    }
  }

  get open() {
    return this.linkElement.classList.contains("open");
  }

  close() {
    if (this.open) {
      this.toggle();
    }
  }

  toggle() {
    this.linkElement.classList.toggle("open");
    this.targetElement.classList.toggle("open");
    this.menu.dropdownToggled();
  }

  mouseover() {
    this.clearCloseTimeout();

    if (!this.open) {
      this.toggle();
    }
  }

  mouseout() {
    this.clearCloseTimeout();
    this.startCloseTimeout();
  }

  clearCloseTimeout() {
    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }
  }

  startCloseTimeout() {
    this.timeoutID = window.setTimeout(() => {
      this.close();
    }, 100);
  }

  bindObservers() {
    this.bindToggleMenuDropdown();
  }

  bindToggleMenuDropdown() {
    this.linkElement.addEventListener("click", (event) => {
      event.stopPropagation();
      event.preventDefault();

      this.toggle();
    });

    this.linkElement.addEventListener("mouseover", (_event) => {
      this.mouseover();
    });

    this.linkElement.addEventListener("mouseout", (_event) => {
      this.mouseout();
    });

    this.targetElement.addEventListener("mouseover", (_event) => {
      this.mouseover();
    });

    this.targetElement.addEventListener("mouseout", (_event) => {
      this.mouseout();
    });
  }
}

class Menu {
  constructor() {
    this.bindObservers();
    this.initializeDropdownItems();
  }

  initializeDropdownItems() {
    this.dropdownItems = [];

    window.addEventListener("load", (_event) => {
      let linkElements = document.querySelectorAll("a[data-toggle]");
      var menu = this;

      linkElements.forEach((linkElement) => {
        menu.dropdownItems.push(new DropDownItem(menu, linkElement));
      });
    });
  }

  closeMobileMenu() {
    document.getElementById("menu-btn").checked = false;
  }

  maybeRightAlignMenu() {
    let firstLevelLeftSubmenus = document.querySelectorAll(
      "ul.menu:not(.navigation-item) > li.submenu > ul.menu:not(.right)"
    );

    let maxX = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );

    firstLevelLeftSubmenus.forEach((firstLevelLeftSubmenu) => {
      window.firstLevelLeftSubmenu = firstLevelLeftSubmenu;

      let menuX = 0;
      let menuWidth = firstLevelLeftSubmenu.offsetWidth;
      var element = firstLevelLeftSubmenu;

      while (element) {
        menuX += element.offsetLeft - element.scrollLeft + element.clientLeft;
        element = element.offsetParent;
      }

      if (menuX + menuWidth > maxX) {
        firstLevelLeftSubmenu.classList.add("right");
      }
    });
  }

  bindObservers() {
    this.bindCloseDropdownClick();
    this.bindCloseMobileMenuObserver();
    this.bindMaybeRightAlignMenu();
  }

  dropdownToggled() {
    this.maybeRightAlignMenu();
  }

  bindCloseDropdownClick() {
    document.addEventListener("click", (_event) => {
      this.dropdownItems.forEach((dropdownItem) => {
        dropdownItem.close();
      });
    });
  }

  bindCloseMobileMenuObserver() {
    document.addEventListener("click", (event) => {
      if (event.target.closest("ul.menu")) {
        this.closeMobileMenu();
      }
    });
  }

  bindMaybeRightAlignMenu() {
    window.addEventListener("load", (_event) => {
      this.maybeRightAlignMenu();
    });
  }
}

new Menu();
